.show {
    display: block;
}
.hide {
    display: none;
}
.success-gif {
    width: 60px;
    margin-top: 30px;
}
// Records table CSS
.records-tbl .p-datatable-thead {
    background-color: #D4E5FF;
}
.records-tbl .p-datatable-thead > tr > th{
    color: #0549AF;
    padding: 15px 10px;
}
// Records details dialog CSS
.dlg-case-details .p-dialog-header, 
.dlg-case-close .p-dialog-header, 
.dlg-case-new .p-dialog-header,
.dlg-case-custom .p-dialog-header
{
    display: none;
}
.dlg-case-details .p-dialog-content, 
.dlg-case-close .p-dialog-content,
.dlg-case-new .p-dialog-content,
.dlg-case-custom .p-dialog-content
{
    background: #fff;
    padding: 0px;
    border-radius: 20px;
}
.dlg-case-details .p-badge {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 80px;
    background-color: #FCE7F3;
    color: #9E194F;
}
.dlg-case-details .p-badge.closed {
    background-color: #DCFCE7;
    color: #277244;
}
.dlg-case-details .p-badge.date {
    right: 8px;
    left: unset;
    width: 183px;
    background-color: #fff;
    color: #898888;
}
.dlg-case-details .info-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;
    padding: 16px 20px;
}

.dlg-case-details .info-block .items {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.6px;
    color: #ABABAB;
    padding: 15px 10px;
}
.dlg-case-details .info-block .items span{
    color: #000000;
}
.dlg-case-details .br-line {
    background-image: linear-gradient(to right, #0066FF 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    height: 2px;
    margin-top: -13px;
}
.dlg-case-details .footer-content {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #0549AF;
    padding: 20px;
}
.dlg-case-details .footer-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30px;
}
.dlg-case-details .btn-close-case {
    background-color: #3B82F6;
    mix-blend-mode: normal;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 14px;
    color: #fff;
    position: absolute;
    bottom: -18px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.6px;
    color: #FFFFFF;
}
.dlg-case-details .btn-close-dlg {
    bottom: -60px;
    position: absolute;
    background-color: #fff;
    color: #D85757;
}

// Close case Dialog 
.dlg-case-close .block-pnl {
    padding: 20px;
}
.dlg-case-close .block-pnl .he-closer-option, .dlg-case-close .block-pnl .he-closer-note {
    font-weight: 600;
}
.dlg-case-close .footer-btns, 
.dlg-case-new .footer-btns,
.dlg-case-custom .footer-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30px;
    margin-top: 30px;
}
.dlg-case-close .selBadge, 
.dlg-case-new .selBadge,
.dlg-case-custom .selBadge {
    margin: 10px 0px;
    background-color: #D4E5FF;
    border-radius: 7px;
    padding: 10px;
    width: fit-content;
    min-width: 350px;
    color: #0549AF;
    font-weight: 600;
    position: relative;
}
.dlg-case-close .selBadge .close-icon, 
.dlg-case-new .selBadge .close-icon,
.dlg-case-custom .selBadge .close-icon {
    float: right;
    border-radius: 50%;
    transform: scale(0.7);
    position: absolute;
    right: 2px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background: url('../assets/icons/remove.svg');
    background-size: 100% 100%;
    cursor: pointer;
    top: 28%;
  }
.dlg-case-close .close-dlg-btn, 
.dlg-case-new .close-dlg-btn,
.dlg-case-custom .close-dlg-btn {
    float: right;
    position: absolute;
    right: 15px;
    top: 15px;
}
.dlg-case-close .close-dlg-btn .p-button-rounded, 
.dlg-case-new .close-dlg-btn .p-button-rounded,
.dlg-case-custom .close-dlg-btn .p-button-rounded {
    height: 20px;
    width: 20px;
    background-color: #000;
}
.dlg-case-close .close-dlg-btn .p-button-rounded .p-button-icon, 
.dlg-case-new .close-dlg-btn .p-button-rounded .p-button-icon,
.dlg-case-custom .close-dlg-btn .p-button-rounded .p-button-icon {
    font-size: 10px;
}

.dlg-case-close .fb-msg {
    text-align: center;
    padding: 30px;
    color: #000;
    font-size: 15px;
}
.dlg-case-close .fb-msg label {
    font-weight: 500;
}
.dlg-case-close .fb-msg p {
    margin-top: 30px;
    margin-bottom: 57px;
}


// Report new issue dialog 
.dlg-case-new .block-panel {
    padding: 20px;
}
.dlg-case-new .block-panel .heading {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
}
.dlg-case-new .bold {
    font-weight: 500;
}
.dlg-case-new .dropdown {
    margin-top: 12px;
    min-width: 230px;
}
.dlg-case-new .issue-fb {
    text-align: center;
    margin-top: 28px;
    font-weight: 500;
    font-size: 15px;
}
.dlg-case-new .issue-fb p {
    line-height: 35px;
}
.dlg-case-new .p-autocomplete input {
    width: 100%;
}

// Create new custom issue dialog 
.dlg-case-custom .block-pnl {
    padding: 20px;
}
.dlg-case-custom .block-pnl .heading {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
}
.dlg-case-custom .bold {
    font-weight: 500;
}
.dlg-case-custom .dropdown {
    margin-top: 12px;
    min-width: 230px;
}
.dlg-case-custom .ele-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.dlg-case-custom .ele-block .field {
    margin-right: 23px;
    margin-bottom: 20px;
}
.dlg-case-custom .ele-block .field {
    font-weight: 600;
}
.dlg-case-custom .ele-block .field input,
.dlg-case-custom .ele-block .field .p-dropdown {
    margin-top: 10px;
}
.dlg-case-custom .ele-block .field .p-dropdown input {
    margin-top: 0px;
}
.dlg-case-custom .ele-block.details {
    justify-content: space-between;
    margin-top: 40px;
}

.dlg-case-custom .ele-block.details .field label {
    color: #ABABAB;
}
.dlg-case-custom .vmore-btn {
    display: flex;
    justify-content: center;
}
.dlg-case-custom .vmore-btn button{
    border-radius: 25px;
    padding: 7px 20px;
}
.dlg-case-custom .ele-block.view-more {
    margin-top: 8px;
}
.dlg-case-custom .ele-block.hidden {
    display: none;
}
.dlg-case-custom .cat-select{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.dlg-case-custom .cat-select .items {
    width: 50%;
}
.dlg-case-custom .cat-select .items .p-dropdown {
    width: 90%;
}
.dlg-case-custom .fb-msg {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    min-height: 250px;
    padding-top: 50px;
}
.dlg-case-custom .addProducts {
    position: absolute;
    right: -10px;
    margin-top: 29px;
}
.dlg-case-custom .delete-prod {
    position: absolute;
    margin-top: 17px;
    cursor: pointer;
}

// Dark Theme
// Records table CSS
.cac-dark .records-tbl .p-datatable-thead {
    background-color: #0E2A52;
}
.cac-dark .records-tbl .p-datatable-thead > tr > th{
    color: #FFFFFF;
    padding: 15px 10px;
}
.cac-dark .p-dropdown-trigger-icon {
    color: #3D7BD5;
}
.dlg-case-details.dark .p-dialog-content {
    background: #28272A;
    padding: 0px;
    border-radius: 20px;
}
.dlg-case-details.dark .p-badge {
    background-color: #563333;
    color: #FF7070;
}
.dlg-case-details.dark .p-badge.closed {
    background: rgba(58, 106, 69, 0.6);
    color: #06CF51;
}
.dlg-case-details.dark .p-badge.date {
    background-color: #28272A;
    color: #fff;
}
.dlg-case-details.dark .info-block .items {
    color: #b8b8b8;
}
.dlg-case-details.dark .info-block .items span {
    color: #fff;
}
.dlg-case-details.dark .footer-content {
    color: #6BA7FF;
}
.dlg-case-details.dark .br-line {
    background-image: linear-gradient(to right, #6BA7FF 33%, rgba(255, 255, 255, 0) 0%);
}
.dlg-case-details.dark .btn-close-dlg {
    background-color: #28272A;
    box-shadow: none;
}

// Close issue dialog dark theme 
.dlg-case-close.dark .p-dialog-content {
    background: #28272A;
    padding: 0px;
    border-radius: 20px;
}
.dlg-case-close.dark .p-dropdown-trigger-icon {
    color: #3D7BD5;
}
.dlg-case-close.dark .selBadge {
    background-color: #0E2A52;
    color: #FFFFFF;
}
.dlg-case-close.dark .fb-msg {
    color: #FFFFFF;
}


//New  issue dialog dark theme 
.dlg-case-new.dark .p-dialog-content {
    background: #28272A;
    padding: 0px;
    border-radius: 20px;
}
.dlg-case-new.dark .p-dropdown-trigger-icon {
    color: #3D7BD5;
}
.dlg-case-new.dark .selBadge {
    background-color: #0E2A52;
    color: #FFFFFF;
}
.dlg-case-new.dark .fb-msg {
    color: #FFFFFF;
}


//New cusotm order issue dialog dark theme 
.dlg-case-custom.dark .p-dialog-content {
    background: #28272A;
    padding: 0px;
    border-radius: 20px;
}
.dlg-case-custom.dark .p-dropdown-trigger-icon {
    color: #3D7BD5;
}
.dlg-case-custom.dark .selBadge {
    background-color: #0E2A52;
    color: #FFFFFF;
}
.dlg-case-custom.dark .fb-msg {
    color: #FFFFFF;
}

