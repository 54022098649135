.fc-prevYear-button,
.fc-nextYear-button,
.fc-prev-button,
.fc-next-button,
.fc-today-button {
  display: none !important;
}
.gap-1by4 {
  gap: 0.25rem;
}
.gap-1by2 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.w-full {
  width: 100%;
}

.flex-grow {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.text-center {
  text-align: center;
}

.font-sm {
  font-size: 0.8rem;
}

.font-md {
  font-size: 1.2rem;
}

.font-lg {
  font-size: 1.6rem;
}

.font-xl {
  font-size: 2.4rem;
}

.font-2xl {
  font-size: 3.2rem;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.cursor-default {
  cursor: default;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

@for $i from 0 through 100 {
  .h-#{$i} {
    height: #{$i}rem;
  }
  .w-#{$i} {
    width: #{$i}rem;
  }
  .min-h-#{$i} {
    min-height: #{$i}rem;
  }
  .min-w-#{$i} {
    min-width: #{$i}rem;
  }
  .max-h-#{$i} {
    max-height: #{$i}rem;
  }
  .max-w-#{$i} {
    max-width: #{$i}rem !important;
  }
  .top-#{$i}-px {
    top: #{$i}px;
  }
  .top-#{$i}-rem {
    top: #{$i}rem;
  }
  .top-#{$i} {
    top: #{$i}+ '%';
  }
  .bottom-#{$i}-px {
    bottom: #{$i}px;
  }
  .bottom-#{$i}-rem {
    bottom: #{$i}rem;
  }
  .bottom-#{$i} {
    bottom: #{$i}+ '%';
  }
  .left-#{$i}-px {
    left: #{$i}px;
  }
  .left-#{$i}-rem {
    left: #{$i}rem;
  }
  .right-#{$i}-px {
    right: #{$i}px;
  }
  .right-#{$i}-rem {
    right: #{$i}rem;
  }
}

.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-screen-50 {
  max-height: 50vh;
}

.max-h-screen-60 {
  max-height: 60vh;
}

.max-h-screen-70 {
  max-height: 70vh;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

.chat-wrapper {
  margin-bottom: 1rem;
}
.chat {
  width: fit-content;
  max-width: 70%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  border-bottom-left-radius: 0px;

  background-color: #6c1af2;
  color: white;
  position: relative;
}

.chat.own {
  border-radius: 0.75rem;
  border-bottom-right-radius: 0px;
  margin-left: auto;
  background-color: #0f8bfd;
  color: white;
}

.timestamp.own {
  display: flex;
  justify-content: end;
}

.chats {
  overflow-y: scroll;
}

.dg-header {
  position: sticky;
  top: 0;
  z-index: 500;
}
.dialog-ac-con {
  margin-top: 0px;
}
// change order status dropdown label color according to status
.os-Draft {
  background-color: #d455e7;
}
.os-Partial {
  background-color: #0288d1;
}
.os-Pending {
  background-color: #027eef;
}
.os-Processed {
  background-color: #a0d905;
}

.os-Shipped {
  background-color: #007067;
}
.os-Tracking {
  background-color: #d62f89;
}
.os-Tracking-Live {
  background-color: #c91593;
}
.os-Delivered {
  background-color: #2fc22c;
}
.os-Undelivered {
  background-color: #644141;
}
.os-Cancelled {
  background-color: #c82222;
}
.os-Ineligible {
  background-color: #262626;
}
.os-Ineligible {
  background-color: #262626;
}
.os-Reshipment-Initiated {
  background-color: #00b0a2;
}
.os-Reshipment-Completed {
  background-color: #039286;
}
.os-Refund-Initiated {
  background-color: #be00be;
}
.os-Refund-Completed {
  background-color: #a102a1;
}
.os-Refunded {
  background-color: #a102a1;
}
.os-Ineligi-Cancel {
  background-color: #262626;
}
.os-Completed {
  background-color: #2fc22c;
}
.rtemail {
  color: #fff !important;
}

.rank-datatable {
  .p-datatable .p-column-header-content {
    justify-content: center;
  }
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.tm-status-Pending {
  background-color: #fefce8;
  color: #874d1e;
}

.tm-status-Inactive {
  background-color: #f9fafb;
  color: #4b5563;
}

.tm-status-Upcoming {
  background-color: #faf5ff;
  color: #8025cf;
}

.tm-status-Unachieved {
  background-color: #fef2f2;
  color: #b92028;
}

.tm-status-Achieved {
  background-color: #f0fdf4;
  color: #1b8857;
}

.tm-status-In-Progress {
  background-color: #eff6ff;
  color: #2c4ed8;
}

// short css for tinymce warning hide
.tox-notification.tox-notification--in.tox-notification--warning {
  display: none;
}

.blob {
  background: #60a5fa;
  border-radius: 50%;
  margin: 10px;
  height: 13px;
  width: 13px;
  position: absolute;
  box-shadow: 0 0 0 0 rgba(96, 165, 250, 1);
  transform: scale(1);
  animation: pulse 1.7s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(96, 165, 250, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(96, 165, 250, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(96, 165, 250, 0);
  }
}

//Ticket Management scss
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50px;
}
//Ticket Management scss

// enquiry management
.highlight-dot::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #3b82f6;
  top: 4px;
  left: 2px;
}
.highlight-dot.red::before {
  background-color: red;
}
.highlight-dot.mar::before {
  background-color: #c2185b;
}
.highlight-dot.org::before {
  background-color: orange;
}
.highlight-dot.navi::before {
  background-color: navy;
}
.highlight-dot.pur::before {
  background-color: purple;
}

.text-white {
  color: white;
}
.text-red {
  color: red;
}
// enquiry management
