.sales-card {
  font-size: 16px;
  font-weight: 500;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  .large-text {
    font-size: 16px;
    font-weight: 600;
  }
  .text-blue {
    font-weight: 700;
    color: var(--sales-text) !important;
  }

  .header-bg {
    border-radius: 16px;
    color: white !important;
    text-align: center;
  }

  .paid-sales-toggle-btn {
    // background-color: transparent;
    border-radius: 100%;
    height: 1.7rem;
    width: 1.7rem;
    // background-color: var(--primary-color);
  }

  .sales-view-btn {
    font-weight: 600;
    font-size: 18px;
  }
}
.sales-conversion-card {
  font-size: 18px;
  font-weight: 500;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  .large-text {
    font-size: 18px;
    font-weight: 600;
  }
  .text-blue {
    font-weight: 700;
    color: var(--convertion-text) !important;
  }
}
.sales-today {
  .header-bg {
    background: url('../images/db_sales_today.png') no-repeat center center;
    background-size: cover;
    padding: 0.75rem 0px;
  }
}

.sales-week {
  .header-bg {
    background: url('../images/db_sales_week.png') no-repeat center center;
    background-size: cover;
    padding: 0.75rem 0px;
  }
}

.sales-month {
  .header-bg {
    background: url('../images/db_sales_month.png') no-repeat center center;
    background-size: cover;
    padding: 0.75rem 0px;
  }
}

.leaderboard-card {
  position: relative;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  .header-bg {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff !important;
    text-align: center;
    mix-blend-mode: normal;
    border-radius: 12px;
    background: linear-gradient(90deg, #005bea 0%, #039dc7 100%);
  }

  .lb-card {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 18px;
  }
  .header-bg {
    padding: 0.75rem 0px;
    border-radius: 16px;
  }
  .lb-header {
    padding: 0.5rem 7px;
    color: #777777;
    font-size: 16px;
    font-weight: 500;
    padding: 0.75rem 0px;
  }
  .lb-record {
    font-size: 16px;
    height: 3.2rem;
    font-weight: 500;
  }
  .lb-rank-image {
    height: 2.5rem;
  }
  .text-blue {
    font-weight: 700;
    color: var(--sales-text) !important;
  }
  .collapsed-btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    :hover {
      background: var(--bg-card-color);
    }
    button {
      width: 138px;
      height: 41px;
      background: var(--card) !important;
      color: var(--primary-color) !important;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      mix-blend-mode: normal;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 18px;
    }
  }
}
.target-card {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

  .target-stats-card {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  }

  .target-level-text {
    color: var(--primary-color) !important;
  }

  .header-bg {
    padding: 0.75rem 0px;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff !important;
    text-align: center;
    mix-blend-mode: normal;
    border-radius: 12px;
    background: linear-gradient(90deg, #005bea 0%, #039dc7 100%);
  }

  .collapsed-btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    :hover {
      background: var(--bg-card-color);
    }
    button {
      width: 138px;
      height: 41px;
      background: var(--card) !important;
      color: var(--primary-color) !important;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      mix-blend-mode: normal;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 18px;
    }
  }
}

.primary-text-color {
  color: var(--primary-color) !important;
}
